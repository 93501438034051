// axios
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: process.env.VUE_APP_GATEWAY_API_ENDPOINT,
  headers: { 'content-Type': 'application/json', 'cache-control': 'no-cache' },
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => {
    Promise.reject(error)
  },
)

axiosIns.interceptors.response.use(response => response, err => {
  if (err.response.status === 401) {
    localStorage.clear()
    window.location.href = '/login'
  }

  return Promise.reject(err)
})

Vue.prototype.$http = axiosIns

export default axiosIns
